<template>
  <Subheader :stoStatus="activeSto" type="investments" v-if="loggedIn" />
  <div class="container" v-if="loggedIn === false">
    <div class="row mt-5 pt-5">
      <div class="col-12 not-loggedin">
        <h1 class="font-primary success-heading text-center w-100">You need to sign in</h1>
        <p class="auth-explanation-text">In order to proceed to this page, you will need to sign in with your BLSO account. If you don't have an account yet then you can register via the button indicated below.</p>
        <router-link to="/login" class="button">Sign In</router-link>
        <router-link to="/register" class="mt-4">Register</router-link>
      </div>
    </div>
  </div>
  <div v-else class="container pt-5">
    <div class="mt-5 pt-5" v-if="dashboardInfoError">
      <div class="row mt-2 pt-5">
        <div class="d-flex flex-column align-items-center col-12 py-5">
          <p class="text-center">There was an error loading your investment information</p>
          <button @click="getDashboardData" class="button">Retry</button>
        </div>
      </div>
    </div>
    <div v-else-if="Object.keys(accountStats).length" :class="true ? 'offset-top' : ''" class="row content mb-5">
      <div
        class="col-12 wallet-top-controls align-items-end d-flex justify-content-between"
      >
        <h2 class="font-primary mb-0">Account Stats</h2>
        <div class="right-controls d-flex align-items-center">
          <button
            @click="showDepositModal = true;"
            class="button invest-btn mx-1"
            v-if="activeSto?.status === 2"
          >
            Invest
          </button>
        </div>
      </div>
      <div v-if="Number(accountStats.estimated_shares) > 0 && Number(accountStats.shares) == 0" class="col-12 col-lg-4 col-xl-4">
        <div class="wallet-card">
          <h3 class="mb-3">Estimated shares</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="fiveIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats.estimated_shares
            }}</span>
            <span class="wallet-card-label">SHARES</span>
          </div>
        </div>
      </div>
      <div v-if="Number(accountStats.shares) > 0" class="col-12 col-lg-4 col-xl-4">
        <div class="wallet-card">
          <h3 class="mb-3">Shares</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="fiveIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats.shares
            }}</span>
            <span class="wallet-card-label">SHARES</span>
          </div>
        </div>
      </div>
      <div v-if="accountStats.token_price !== null" class="col-12 col-lg-4 col-xl-4">
        <div class="wallet-card">
          <h3 class="mb-3">Price per share</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats.token_price
            }}</span>
            <span class="wallet-card-label">EUR</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <div class="wallet-card">
          <h3 class="mb-3">Pre-Allocated funds</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats?.allocated_funds
            }}</span>
            <span class="wallet-card-label">EUR</span>
          </div>
        </div>
      </div>
      <div
        v-if="Number(accountStats.non_allocated_funds_btc) > 0"
        class="col-12 col-lg-4 col-xl-4"
      >
        <div class="wallet-card">
          <h3 class="mb-3">Non-allocated funds</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="btcBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats?.non_allocated_funds_btc
            }}</span>
            <span class="wallet-card-label">BTC</span>
          </div>
        </div>
      </div>
      <div
        v-if="parseFloat(accountStats?.funds_left_over.replace(',', '')) > 0"
        class="col-12 col-lg-4 col-xl-4"
      >
        <div class="wallet-card">
          <h3 class="mb-3">Funds left over</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              (accountStats?.funds_left_over)
            }}</span>
            <span class="wallet-card-label">EUR</span>
          </div>
        </div>
      </div>
      <div
        v-if="Number(accountStats.non_allocated_funds) > 0"
        class="col-12 col-lg-4 col-xl-4"
      >
        <div class="wallet-card">
          <h3 class="mb-3">Non-allocated funds</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="lastSto?.display_status === 'Active' ? eurBagIcon : returnIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats.non_allocated_funds
            }}</span>
            <span class="wallet-card-label">EUR</span>
          </div>
        </div>
      </div>
      <div v-if="Number(accountStats?.pending_eur.replace(',', '')) > 0 && accountStats?.pending_eur !==  null" class="col-12 col-lg-4 col-xl-4">
        <div class="wallet-card">
          <h3 class="mb-3">Pending funds</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              accountStats.pending_eur
            }}</span>
            <span class="wallet-card-label">EUR</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 pt-5" v-else>
      <div class="loader"></div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="font-primary">Order history</h2>
      </div>
    </div>
    
    <div class="row row-eq-height mb-5 pb-3 mt-3 pt-4">

      <div
        v-if="Array.isArray(transactions) && transactions.length > 0"
        class="col-12 d-flex flex-wrap"
      >
        <div
          class="token-transaction"
          :key="transaction"
          v-for="transaction of transactions"
        >

          <div class="transaction-section mt-0 d-flex align-items-center">
            <div class="h-100 d-flex align-items-start pt-2">
              <img class="me-2 icon" :src="clockIcon" alt="" />
            </div>
            <div class="d-flex flex-column">
              <span class="d-inline-block mb-2">{{formatDate(transaction.created_at)[0]}}</span>
              <span>{{formatDate(transaction.created_at)[1]}}</span>
            </div>
          </div>

          <div class="transaction-section d-flex flex-column align-items-start from-to">
            <p class="text-nowrap mb-0">From: <span class="bold">{{transaction.display_from}}</span></p>
            <p class="text-nowrap">To: <span class="bold">{{transaction.display_to}}</span></p>
          </div>

          <div :class="transaction.details?.conversion ? 'conversion' : ''" class="transaction-section d-flex align-items-center">
            
            <div class="from-to" v-if="transaction.details?.conversion">
              <div class="d-flex flex-nowrap">
                <img class="icon me-1" :src="eurIcon" alt="" />
                <span
                  class="transaction-address"
                >{{ transaction.details.conversion.from_amount }}
                  BTC</span
                >
              </div>
              <img class="mx-3 arrow-icon" :src="arrowRight2" alt="">
              <div class="d-flex flex-nowrap">
                <img class="icon me-1" :src="eurIcon" alt="" />
                <span
                  class="transaction-address"
                >{{ transaction.details.conversion.to_amount }}
                  EUR</span
                >
              </div>
            </div>
            <div class="transaction-amount" v-else>
              <img class="icon me-1" :src="eurIcon" alt="" />
              <span
                class="transaction-address"
              >
                {{ transaction.display_amount }} {{ transaction.currency_code }}
              </span>
            </div>
          </div>

          <div class="transaction-section d-flex align-items-center">
            <span>Completed</span>
          </div>

          <div class="transaction-section details d-flex align-items-center">
            <a
              v-if="transaction.details && transaction.details.tx_hash"
              class="button white py-2"
              target="_blank"
              :href="`${config.btcExplorerUrl}/${transaction.details?.tx_hash}`"
            >
              Details
            </a>
            <div v-else-if="transaction.details && transaction.details.price_per_share" class="conversion-rate">
              <img class="icon me-1" :src="eurIcon" alt="">
              <span>{{transaction.details.price_per_share}} &nbsp; </span>
              <small>/ share</small>
            </div>
            <div v-else-if="transaction.details && transaction.details.conversion_rate" class="conversion-rate d-flex flex-nowrap">
              <img class="icon me-1" :src="eurIcon" alt="">
              <span class="text-nowrap">{{transaction.details && transaction.details.conversion_rate}} EUR &nbsp; </span>
              <small class="text-nowrap"> / BTC</small>
            </div>
            <!-- <div v-else>
              N/A
            </div> -->
          </div>

        </div>

        <div
          class="
            pagination-wrapper
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <button
            :disabled="currentPage === 1"
            @click="getTransactions(firstPage)"
            class="button white"
          >
            &lt;&lt; <span>First</span>
          </button>
          <button
            :disabled="!prevPage"
            @click="prevPage && getTransactions(Number(prevPage))"
            class="button white"
          >
            &lt;<span>Previous</span>
          </button>
          <span class="pagination-current">{{currentPage}} / {{lastPage}}</span>
          <button
            :disabled="!nextPage"
            @click="nextPage && getTransactions(Number(nextPage))"
            class="button white"
          >
            <span>Next</span> &gt;
          </button>
          <button
            :disabled="lastPage == currentPage"
            @click="lastPage && getTransactions(Number(lastPage))"
            class="button white"
          >
            <span>Last</span> &gt;&gt;
          </button>
        </div>
      </div>
      <div v-else>
        <p class="text-center mt-5">You have no orders yet.</p>
      </div>
    </div>
  </div>

  <div
    @click="closeModal"
    @scroll.stop
    @scroll.prevent
    :class="showDepositModal || showWithdrawModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div @click.stop v-if="showDepositModal" class="deposit-modal modal">
      <div @click="closeModal" class="close-modal">✕</div>
      <h3 class="text-center mb-4">Invest</h3>
      <div v-if="qrUrl" class="modal-tabs mb-4">
        <div
          @click="depositCurrency = 'BTC'"
          :class="depositCurrency === 'BTC' ? 'active' : ''"
          class="modal-tab me-1"
        >
          <span>Bitcoin</span>
        </div>
        <div
          @click="depositCurrency = 'EUR'"
          :class="depositCurrency === 'EUR' ? 'active' : ''"
          class="modal-tab"
        >
          <span>Euro</span>
        </div>
      </div>

      <div
        class="d-flex align-items-center justify-content-center my-2"
        v-if="loadingDepositInfo"
      >
        <div class="loader">Loading...</div>
      </div>
      <template v-else-if="depositCurrency === 'EUR'">
        <p class="text-center mb-4">
          Please only send funds from the bank account that you have provided during account registration in EURO. All information for the bank transfer are provided below. Please double check that you insert the correct information.
        </p>

        <div class="invest-bank-info">
          <span class="bank-info-row"
            >Bank: <strong>{{ fiatDepositInfo?.bank_name }}</strong></span
          >
          <span class="bank-info-row"
            >Account holder: <strong>TEN31 Bank AG</strong></span
          >
          <span class="bank-info-row"
            >Address: <strong>{{ fiatDepositInfo?.bank_address }}</strong></span
          >
          <br />
          <span class="bank-info-row"
            >Bank account: <strong>{{fiatDepositInfo?.bank_account}}</strong></span
          >
          <span class="bank-info-row"
            >Reference number:
            <strong>{{ fiatDepositInfo?.reference_number }}</strong></span
          >
        </div>
        <small class="text-center">It may take up to 3 days for transaction to be visible in the system.</small>
      </template>
      <template v-else>
        <p class="mt-2 modal-p mb-2 text-center">
          You should deposit at least 5 EUR worth of selected cryptocurrency to this address. Otherwise, your deposit may not be picked up by our system.
        </p>
        <img class="qr-code mb-2" :src="qrUrl" alt="" />
        <label for="deposit-address">Bitcoin deposit address</label>
        <input
          disabled
          :value="cryptoDepositInfo?.address?.address"
          type="text"
          style="background-color: white; color: #848FA8; opacity: 0.7"
        />
        <label class="mt-4">Disclaimer</label>
        <p>
          I acknowledge that by depositing Bitcoin to any other address than the one provided above or depositing any other cryptocurrency to the provided address, may result in your funds being lost.
        </p>
      </template>

      <button
        @click="depositCurrency == 'BTC' ? copyAddress() : closeModal()"
        class="button danger mt-3"
      >
        {{
          depositCurrency == "EUR"
            ? "Close"
            : addressCopied
            ? "Copied!"
            : "Copy address"
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import QRCode from "qrcode";
import config from "../../config";
import fiveIcon from "../../assets/images/icon-share-1.svg";
import eurBagIcon from "../../assets/images/icon-euro-funds.svg";
import eurIcon from "../../assets/images/icon-euro.svg";
import btcBagIcon from "../../assets/images/icon-btc-funds.svg";
import returnIcon from "../../assets/images/icon-return.svg";
import arrowRight2 from "../../assets/images/arrow-right2.svg";
import clockIcon from "../../assets/images/clock2.svg";
import btcIcon from "../../assets/images/icon-btc.svg";
import Subheader from '../../components/Subheader.vue';

export default defineComponent({
  name: "Wallet",
  components: {
    Subheader,
  },
  data: function () {
    return {
      lastSto: null,
      userData: null,
      config: config,
      addressCopied: false,
      btcIcon: btcIcon,
      clockIcon: clockIcon,
      arrowRight2: arrowRight2,
      accountStats: {},
      fiveIcon: fiveIcon,
      eurBagIcon: eurBagIcon,
      eurIcon: eurIcon,
      btcBagIcon: btcBagIcon,
      returnIcon: returnIcon,
      orders: [],
      tokenBalance: "",
      tokenPrice: "",
      tokenTotalValue: "",
      completedOrders: "",
      pendingOrders: "",
      showDepositModal: false,
      showWithdrawModal: false,
      depositCurrency: "BTC",
      depositAddress: "abcdefghjklmnopqrstuvwxyz1234567890",
      qrUrl: "",
      accessToken: "",
      deposits: null,
      withdrawals: null,
      depositCurrencies: [],
      fiatDepositInfo: {},
      cryptoDepositInfo: {},
      loadingDepositInfo: false,
      transactionType: "deposits",
      depositsCurrPage: 1,
      withdrawalsCurrPage: 1,
      orderLoading: false,
      transactions: null,
      nextPage: null,
      prevPage: null,
      currentPage: null,
      lastPage: null,
      loggedIn: true,
      activeSto: null,
      cryptoError: false,
      dashboardInfoError: false
    };
  },
  mounted: function () {
    this.loggedIn = this.$store.state.loggedIn;
    this.$store.watch(state => {
      this.loggedIn = state.loggedIn;
      this.accessToken = state.accessToken;
      this.userData = state.userData;
      if (state.accessToken) {
        this.getDashboardData();
        this.getDepositInfo(this.depositCurrency);
        this.getTransactions();
        this.getLastSto();
      }
    });
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const displayDate = `${year}-${this.twoDigits(month)}-${this.twoDigits(day)} ${this.twoDigits(hours)}:${this.twoDigits(minutes)}:${this.twoDigits(seconds)} CET`
      return [displayDate.slice(0, 10), displayDate.slice(10)];
    },
    twoDigits(num) {
      if (num.toString().length < 2) {
        return `0${num}`;
      }
      return num;
    },
    getLastSto() {
      fetch(`${config.API}/api/v1/last-sto`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        }
      })
      .then(res => res.json())
      .then(data => {
        this.activeSto = data.data.sto;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getTransactions(pageNum = 1) {
      fetch(`${config.API}/api/v1/transactions?page=${pageNum}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.transactions = data.data.transactions.data;
          this.prevPage = data.data.transactions?.prev_page_url
            ? Number(data.data.transactions.current_page) - 1
            : null;
          this.nextPage = data.data.transactions?.next_page_url
            ? Number(data.data.transactions.current_page) + 1
            : null;
          this.lastPage = data.data.transactions?.last_page;
          this.firstPage = data.data.transactions?.first_page;
          this.currentPage = data.data.transactions?.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    copyAddress() {
      this.addressCopied = true;
      navigator.clipboard.writeText(this.cryptoDepositInfo.address.address);
      this.$toast.success("Copied!");
    },
    async getWithdrawals(pageNum = 1) {
      this.orderLoading = true;
      try {
        const withdrawalsRes = await fetch(
          `${config.API}/api/v1/withdrawals?page=${pageNum}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        this.withdrawals = withdrawalsRes.data.withdrawals;
        this.orderLoading = false;
      } catch (error) {
        console.log(error);
        this.orderLoading = false;
      }
    },
    async getDepositInfo(currencyCode) {
      this.loadingDepositInfo = true;
      try {
        const res = await fetch(
          `${config.API}/api/v1/get-deposit-info/${currencyCode}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        // console.log(res.errors[0] === 'Failed to get info for deposit.');
        // if (res.errors[0] === 'Failed to get info for deposit.') {
        //   this.depositCurrency = 'EUR';
        //   this.cryptoError = true;
        // }
        this.loadingDepositInfo = false;
        if (currencyCode === "EUR") {
          this.fiatDepositInfo = res.data?.deposit_data;
        } else {
          if (!res.data?.deposit_data?.address.address) {
            this.depositCurrency = 'EUR';
            return;
          }
          this.cryptoDepositInfo = res.data?.deposit_data;
          this.renderQR(res.data?.deposit_data?.address.address);
        }
      } catch (error) {
        console.log(error);
      }
    },
    renderQR(string = 'placeholder-string') {
      QRCode.toDataURL(string, {
        scale: 10,
        color: { light: "#fff", dark: "#3F4C62" },
      })
        .then((url) => {
          if (url) {
            this.qrUrl = url;
          } else {
            this.depositCurrency = 'EUR';
            // this.qrUrl = 'placeholder-url'
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeModal() {
      this.showDepositModal = false;
      this.showWithdrawModal = false;
      this.addressCopied = false;
    },
    async getDashboardData() {
      // this.accountStats = {};
      this.dashboardInfoError = false;
      try {
        const dashboardData = await fetch(
          `${config.API}/api/v1/get-dashboard-info`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        console.log(dashboardData);
        if (dashboardData.errors) {
          this.dashboardInfoError = true;
        }
        this.accountStats = dashboardData.data.dashboard_info;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    // depositCurrency: function (newVal) {
    //   console.log("changed", newVal);
    //   this.getDepositInfo(newVal);
    // },
    // transactionType: function (newVal) {
    //   if (newVal === "deposits") {
    //     this.getDeposits();
    //   } else {
    //     this.getWithdrawals();
    //   }
    // },
    showWithdrawModal: function(newVal) {
      if (newVal) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'auto';
      }
    },
    showDepositModal: function(newVal) {
      if (newVal) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'auto';
      }
    }
  },
});
</script>
